import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo.png"
import { FaInstagram } from "react-icons/fa"

const NavItem = ({ children }) => (
  <li className="uppercase font-medium tracking-wider text-md hover:text-purple-500 transition-colors">
    {children}
  </li>
)

const Nav = () => (
  <div className="flex justify-between items-center text-white">
    <Link to="/">
      <img src={logo} alt="diefotodudes Logo" className="h-10" />
    </Link>
    <nav>
      <ul className="flex items-center justify-center space-x-4">
        {/* <NavItem>Über uns</NavItem>
        <NavItem>Foto</NavItem>
        <NavItem>Video</NavItem>
        <NavItem>Galerie</NavItem>
        <NavItem>Kontakt</NavItem> */}
        <NavItem>
          <a href="mailto:mail@diefotodudes.de" title="E-Mail schreiben">
            mail@diefotodudes.de
          </a>
        </NavItem>
      </ul>
    </nav>
    <a
      href="https://instagram.com/diefotodudes"
      target="_blank"
      rel="noopener noreferrer"
      title="Folge uns auf Instagram"
    >
      <FaInstagram size={20} />
    </a>
  </div>
)

export default Nav
