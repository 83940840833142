import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Nav from "../components/nav"
import Container from "../components/ui/container"

const LegalNotice = () => {
  const { wpPage: data } = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 39 }) {
        content
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Impressum" />
      <div className="bg-gray-900 py-4 md:py-8 w-full mb-16 md:mb-24">
        <Container>
          <Nav />
        </Container>
      </div>
      <div className="max-w-prose mx-auto px-8 prose">
        <h1 className="font-headline text-2xl md:text-3xl mb-4">Impressum</h1>
        <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
      </div>
    </Layout>
  )
}

export default LegalNotice
